/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Button } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Na zakázku"}>
        <SiteHeader />

        <Column className="pb--20 pt--80" name={"info"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--30 pb--40 pt--50" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" style={{"maxWidth":""}} content={"Custom orders"}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized. "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"epdhjuigsbv"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--2 pb--50 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="--left mb--0 mt--0 pb--0 pt--0">
              
              <Subtitle className="subtitle-box" content={"Puff pastry"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Subtitle className="subtitle-box" content={"$17"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--2 pb--50 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="--left mb--0 mt--0 pb--0 pt--0">
              
              <Subtitle className="subtitle-box" content={"Pretzels"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Subtitle className="subtitle-box" content={"$17"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--2 pb--50 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="--left mb--0 mt--0 pb--0 pt--0">
              
              <Subtitle className="subtitle-box" content={"Cakes"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Subtitle className="subtitle-box" content={"$17"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--50" name={"vyzva"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 pb--80 pt--80" anim={"2"} animS={"4"} style={{"maxWidth":1100,"backgroundColor":"rgba(247, 247, 247, 1)"}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pt--20" style={{"maxWidth":534}}>
              
              <Title className="title-box" content={"Download the complete list in .pdf"}>
              </Title>

              <Button className="btn-box btn-box--hvr2" href={"/hr/"} content={"Download"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s4 pb--50 pl--40 pr--40 pt--40" anim={"3"} name={"footer"} animS={"4"}>
          
          <ColumnWrap className="column__flex el--3 --full pl--20 pr--20 flex--bottom" style={{"maxWidth":""}} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Bakery Finch Street 24<br>722 Finch Street, Asbury Park, NJ 07712<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":254}} content={"510-851-4014<br>info@vase-stranky.com<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Mon—Fri<br><br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":254}} content={"Sat—Sun<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}